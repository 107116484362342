export default {
  getCartTotal: (state) => {
    if (state?.products?.length > 0) {
      return state.products
        .reduce(
          (sum, obj) => parseFloat(sum) + parseFloat(obj.price * obj.quantity),
          0
        )
        .toFixed(2)
    } else {
      return "0"
    }
  },
  getCartPositionCount: (state) => {
    return state?.products?.length
  },
  getCartProducts: (state) => {
    return state.products
  },
  getProductsForPayParts: (state) => {
    const array = []
    const copy = state.products
    copy.forEach((item) => {
      array.push({
        name: item.product
          ? item.product?.trans_title +
            " " +
            item.option?.trans_title +
            " (довжина " +
            item.product_length +
            " мм)"
          : item.trans_title,
        price: parseFloat(item.price),
        count: parseInt(item.quantity),
      })
    })
    return array
  },
  getProductsForPayMonoParts: (state) => {
    const array = []
    const copy = state.products
    copy.forEach((item) => {
      array.push({
        name: item.product
          ? item.product?.trans_title +
            " " +
            item.option?.trans_title +
            " (довжина " +
            item.product_length +
            " мм)"
          : item.trans_title,
        price: parseFloat(item.price),
        count: parseInt(item.quantity),
        sum: parseFloat((item.price * item.quantity).toFixed(2)),
      })
    })
    return array
  },
  getProductQuantity: (state) => (payload) => {
    if (payload.path?.includes("/opt/") || payload.to?.includes("/opt/")) {
      const product = state.products.find(function (product) {
        const compareErp =
          product.product?.erp_id === payload.product?.erp_id ||
          product.erp_id === payload.erp_id

        return (
          product.id === payload.id &&
          product.product_length === payload.product_length &&
          compareErp
        )
      })
      return product.quantity
    } else {
      const product = state.products.find(
        (product) => product.id === payload.id
      )
      return product.quantity
    }
  },
  getDataForOrders: (state) => {
    const copyCart = $nuxt.$cloneDeep(state)
    delete copyCart.warehouses
    delete copyCart.cities
    delete copyCart.payment_parts_count
    delete copyCart.show
    for (const key in copyCart.products) {
      delete copyCart.products[key].active
      delete copyCart.products[key].alias
      delete copyCart.products[key].attributes
      delete copyCart.products[key].categories
      delete copyCart.products[key].created_at
      delete copyCart.products[key].description
      delete copyCart.products[key].attachments
      delete copyCart.products[key].seo_keywords
      delete copyCart.products[key].seo_title
      delete copyCart.products[key].updated_at
    }
    return copyCart
  },
  getDialogByProduct: (state) => {
    return state.dialogByProduct
  },
  getCurrentProduct: (state) => {
    return state.tempProduct
  },
  getCurrentCategory: (state) => {
    const tempCategory = state.tempProduct?.categories?.[0]
    return tempCategory ?? state.tempCategory
  },
}
