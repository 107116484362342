export default {
  ADD_NEW_PRODUCT(state, product) {
    const copy = this.$cloneDeep(product)
    copy.amount = (
      (parseFloat(product.product_length) / 1000) *
      parseFloat(product.price) *
      parseInt(product.quantity)
    ).toFixed(2)
    copy.basePrice = copy.price
    copy.price = (
      (parseFloat(product.product_length) / 1000) *
      parseFloat(product.price)
    ).toFixed(2)
    state.products.push(copy)
    // console.log(copy)
    this.$gtm.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "UAH",
        items: [
          {
            item_name:
              copy?.product?.trans_title +
                "-" +
                copy?.characteristic?.trans_title || copy?.trans_title, // Name or ID is required.
            item_id: copy.id,
            price: copy.price,
            item_brand: "Astra-company",
            item_variant: copy?.characteristic?.attributes,
            index: 1,
            quantity: copy.quantity,
            discount: "0",
          },
        ],
        value: copy.amount,
      },
    })
  },
  ADD_EXISTING_PRODUCT(state, product) {
    const copy = this.$cloneDeep(product)
    const item = state.products.find(function (e) {
      return (
        e.id === product.id &&
        parseInt(e.product_length) === parseInt(product.product_length) &&
        e.product_id === product.product_id
      )
    })
    item.quantity = parseInt(item.quantity) + parseInt(copy.quantity)
    item.amount = (parseFloat(item.price) * parseInt(item.quantity)).toFixed(2)
    this.$gtm.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "UAH",
        items: [
          {
            item_name:
              copy?.product?.trans_title + "-" + copy?.option?.trans_title ||
              copy?.trans_title, // Name or ID is required.
            item_id: copy.id,
            price: copy.price,
            item_brand: "Astra-company",
            item_variant: copy.attributes,
            index: 1,
            quantity: copy.quantity,
            discount: "0",
          },
        ],
        value: parseFloat(copy.amount),
      },
    })
  },
  deleteFromCart(state, product) {
    if (product.id) {
      state.products.splice(
        state.products.findIndex(function (e) {
          const compareErp =
            e.product?.erp_id === product?.product?.erp_id ||
            e.erp_id === product?.erp_id

          return (
            e.id === product.id &&
            e.product_length === product.product_length &&
            compareErp
          )
        }),
        1
      )
      this.$gtm.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: "UAH",
          items: [
            {
              item_name:
                product?.product?.name + "-" + product?.option?.name ||
                product?.name, // Name or ID is required.
              item_id: product.id,
              price: product.price,
              item_brand: "Astra-company",
              item_variant: product.attributes,
              index: 1,
              quantity: product.quantity,
              discount: "0",
            },
          ],
          value: product.amount,
        },
      })
    } else {
      state.products.splice(
        state.products.findIndex(function (e) {
          return e.erp_id === product.erp_id
        }),
        1
      )
      this.$gtm.push({
        event: "remove_from_cart",
        ecommerce: {
          currency: "UAH",
          items: [
            {
              item_name: product.name, // Name or ID is required.
              item_id: product.erp_id,
              price: product.price,
              item_brand: "Astra-company",
              item_variant: product.attributes,
              index: 1,
              quantity: product.quantity,
              discount: "0",
            },
          ],
          value: parseFloat(
            (parseFloat(product.price) * parseInt(product.quantity)).toFixed(2)
          ),
        },
      })
    }
  },
  clearProducts(state) {
    state.products = []
  },
  clearOrderID(state) {
    state.orderID = null
    state.orderName = null
  },
  setName(state, payload) {
    state.name = payload
  },
  SET_PROMO_CODE(state, payload) {
    state.coupon = payload
  },
  setOrderId(state, payload) {
    state.orderID = payload
  },
  setOrderName(state, payload) {
    state.orderName = payload
  },
  setSurname(state, payload) {
    state.surname = payload
  },
  setPhone(state, payload) {
    state.phone = payload
  },
  setEmail(state, payload) {
    state.email = payload
  },
  setDeliveryType(state, payload) {
    state.delivery_type = payload
  },
  setPaymentType(state, payload) {
    state.payment_type = payload
  },
  setPaymentPartsCount(state, payload) {
    state.payment_parts_count = payload
  },
  setPaymentMonoPartsCount(state, payload) {
    state.payment_mono_parts_count = payload
  },
  setDeliveryCity(state, payload) {
    state.delivery_details.city = payload
  },
  setDeliveryDetails(state, payload) {
    state.delivery_details.address = payload
  },
  setWareHouses(state, warehouses) {
    state.warehouses = warehouses
  },
  setCities(state, cities) {
    state.cities = cities
  },
  addToCart(state, product) {
    if (state.products.some((e) => e.erp_id === product.erp_id)) {
      const item =
        state.products[
          state.products.findIndex(function (e) {
            return e.erp_id === product.erp_id
          })
        ]
      item.quantity = parseInt(item.quantity) + parseInt(product.quantity)
      item.amount = (parseFloat(item.price) * parseInt(item.quantity)).toFixed(
        2
      )
      this.$gtm.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "UAH",
          items: [
            {
              item_name: item.name, // Name or ID is required.
              item_id: item.erp_id,
              price: item.price,
              item_brand: "Astra-company",
              item_variant: item.attributes,
              index: 1,
              quantity: item.quantity,
              discount: "0",
            },
          ],
          value: parseFloat(
            (parseFloat(item.price) * parseInt(item.quantity)).toFixed(2)
          ),
        },
      })
    } else {
      const copy = this.$cloneDeep(product)
      copy.amount = (
        parseFloat(product.price) * parseInt(product.quantity)
      ).toFixed(2)
      state.products.push(copy)
      this.$gtm.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "UAH",
          items: [
            {
              item_name: copy.name, // Name or ID is required.
              item_id: copy.erp_id,
              price: copy.price,
              item_brand: "Astra-company",
              item_variant: copy.attributes,
              index: 1,
              quantity: copy.quantity,
              discount: "0",
            },
          ],
          value: parseFloat(product.price) * parseInt(product.quantity),
        },
      })
    }
  },
  changeQuantityProduct(state, payload) {
    const item =
      state.products[
        state.products.findIndex(function (e) {
          return e.erp_id === payload.item.erp_id
        })
      ]
    if (payload.action === "increase") {
      item.quantity = parseInt(item.quantity) + parseInt(1)
      item.amount = (parseFloat(item.price) * parseInt(item.quantity)).toFixed(
        2
      )
    }
    if (payload.action === "decrease") {
      item.quantity = parseInt(item.quantity) - parseInt(1)
      item.amount = (parseFloat(item.price) * parseInt(item.quantity)).toFixed(
        2
      )
    }
    if (payload.action === "set") {
      item.quantity =
        parseInt(payload.value) > 0 ? parseInt(payload.value) : parseInt(1)
      item.amount = (parseFloat(item.price) * parseInt(item.quantity)).toFixed(
        2
      )
    }
  },
  changeQuantityOption(state, payload) {
    const item =
      state.products[
        state.products.findIndex(function (e) {
          const compareErp =
            e.product?.erp_id === payload.item?.product?.erp_id ||
            e.erp_id === payload.item?.erp_id

          return (
            e.id === payload.item.id &&
            e.product_length === payload.item.product_length &&
            compareErp
          )
        })
      ]
    if (payload.action === "increase") {
      item.quantity = parseInt(item.quantity) + parseInt(1)
      item.amount = (
        (parseFloat(item.product_length) / 1000) *
        parseFloat(item.basePrice) *
        parseInt(item.quantity)
      ).toFixed(2)
    }
    if (payload.action === "decrease") {
      item.quantity = parseInt(item.quantity) - parseInt(1)
      item.amount = (
        (parseFloat(item.product_length) / 1000) *
        parseFloat(item.basePrice) *
        parseInt(item.quantity)
      ).toFixed(2)
    }
    if (payload.action === "set") {
      item.quantity =
        parseInt(payload.value) > 0 ? parseInt(payload.value) : parseInt(1)
      item.amount = (
        (parseFloat(item.product_length) / 1000) *
        parseFloat(item.basePrice) *
        parseInt(item.quantity)
      ).toFixed(2)
    }
  },
  changeSelectedLength(state, payload) {
    if (payload.pickedItem.product_length) {
      const item =
        state.products[
          state.products.findIndex(function (e) {
            return (
              e.id === payload.pickedItem.id &&
              e.product_length === payload.pickedItem.product_length &&
              e.product.erp_id === payload.pickedItem.product.erp_id
            )
          })
        ]
      item.product_length = payload.pickedLength
      // state.products[state.products.findIndex(function(e){return e.id === payload.picked_item.id && e.product_length === payload.picked_item.product_length && e.product.erp_id === payload.picked_item.product.erp_id})].product_length = new_length
      const amount = (
        (parseFloat(item.product_length) / 1000) *
        parseFloat(item.basePrice) *
        parseInt(item.quantity)
      ).toFixed(2)
      const price = (
        (parseFloat(item.product_length) / 1000) *
        parseFloat(item.basePrice)
      ).toFixed(2)
      item.amount = amount
      item.price = price
    }
  },
  TOGGLE_DIALOG_PURCHASE(state) {
    state.dialogByProduct = !state.dialogByProduct
  },
  SET_CURRENT_PRODUCT(state, payload) {
    state.tempProduct = payload
  },
  SET_CURRENT_CATEGORY(state, payload) {
    state.tempCategory = payload
  },
  CLEAR_CURRENT_PRODUCT(state) {
    state.tempProduct = {}
  },
  CLEAR_CURRENT_CATEGORY(state) {
    state.tempCategory = {}
  },
}
