export default () => ({
  orderID: null,
  orderName: null,
  show: false,
  email: "",
  phone: "",
  name: "",
  surname: "",
  coupon: "",
  payment_type: "",
  payment_parts_count: 0,
  payment_mono_parts_count: 0,
  delivery_type: "",
  delivery_details: {
    city: "",
    address: "",
  },
  products: [],
  cities: [],
  warehouses: [],
  dialogByProduct: false,
  tempProduct: {},
  tempCategory: {},
})
