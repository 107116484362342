import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7453ad1c = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _04fb5942 = () => interopDefault(import('../pages/ConfirmPayment.vue' /* webpackChunkName: "pages/ConfirmPayment" */))
const _0382c473 = () => interopDefault(import('../pages/delivery/index.vue' /* webpackChunkName: "pages/delivery/index" */))
const _33443dee = () => interopDefault(import('../pages/gallery/index.vue' /* webpackChunkName: "pages/gallery/index" */))
const _526845bf = () => interopDefault(import('../pages/guarantee/index.vue' /* webpackChunkName: "pages/guarantee/index" */))
const _4074bab0 = () => interopDefault(import('../pages/my.vue' /* webpackChunkName: "pages/my" */))
const _0efe317e = () => interopDefault(import('../pages/my/compare.vue' /* webpackChunkName: "pages/my/compare" */))
const _f219bcfe = () => interopDefault(import('../pages/my/compare/index.vue' /* webpackChunkName: "pages/my/compare/index" */))
const _34277f32 = () => interopDefault(import('../pages/my/compare/_alias.vue' /* webpackChunkName: "pages/my/compare/_alias" */))
const _117f71d0 = () => interopDefault(import('../pages/my/Discount.vue' /* webpackChunkName: "pages/my/Discount" */))
const _5394a67c = () => interopDefault(import('../pages/my/Orders.vue' /* webpackChunkName: "pages/my/Orders" */))
const _d4d9b7bc = () => interopDefault(import('../pages/my/Profile.vue' /* webpackChunkName: "pages/my/Profile" */))
const _45d3f4d8 = () => interopDefault(import('../pages/my/Reviews.vue' /* webpackChunkName: "pages/my/Reviews" */))
const _5e2d9b2c = () => interopDefault(import('../pages/my/Wishes.vue' /* webpackChunkName: "pages/my/Wishes" */))
const _1d403a92 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _271acabd = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3df15e79 = () => interopDefault(import('../pages/auth/ForgotPassword.vue' /* webpackChunkName: "pages/auth/ForgotPassword" */))
const _515e8a1e = () => interopDefault(import('../pages/auth/Login.vue' /* webpackChunkName: "pages/auth/Login" */))
const _2783c225 = () => interopDefault(import('../pages/auth/Logout.vue' /* webpackChunkName: "pages/auth/Logout" */))
const _60ffd794 = () => interopDefault(import('../pages/auth/Registration.vue' /* webpackChunkName: "pages/auth/Registration" */))
const _95f31b82 = () => interopDefault(import('../pages/auth/ResetPassword.vue' /* webpackChunkName: "pages/auth/ResetPassword" */))
const _5192d412 = () => interopDefault(import('../pages/pages/about_us.vue' /* webpackChunkName: "pages/pages/about_us" */))
const _a7cdb8ee = () => interopDefault(import('../pages/pages/blog.vue' /* webpackChunkName: "pages/pages/blog" */))
const _13a2285a = () => interopDefault(import('../pages/pages/Contacts.vue' /* webpackChunkName: "pages/pages/Contacts" */))
const _302006a8 = () => interopDefault(import('../pages/pages/dealer_invitation.vue' /* webpackChunkName: "pages/pages/dealer_invitation" */))
const _0074fa1a = () => interopDefault(import('../pages/pages/director.vue' /* webpackChunkName: "pages/pages/director" */))
const _c17336d4 = () => interopDefault(import('../pages/pages/panels.vue' /* webpackChunkName: "pages/pages/panels" */))
const _2415d14a = () => interopDefault(import('../pages/pages/pre_order.vue' /* webpackChunkName: "pages/pages/pre_order" */))
const _4fc6e7f0 = () => interopDefault(import('../pages/pages/Ruloni.vue' /* webpackChunkName: "pages/pages/Ruloni" */))
const _1f1bef82 = () => interopDefault(import('../pages/pages/vacancies.vue' /* webpackChunkName: "pages/pages/vacancies" */))
const _0a2d5a84 = () => interopDefault(import('../pages/articles/show/_slug.vue' /* webpackChunkName: "pages/articles/show/_slug" */))
const _7bf27316 = () => interopDefault(import('../pages/payment/mono-confirmation/_id.vue' /* webpackChunkName: "pages/payment/mono-confirmation/_id" */))
const _247b3818 = () => interopDefault(import('../pages/articles/_page.vue' /* webpackChunkName: "pages/articles/_page" */))
const _47314435 = () => interopDefault(import('../pages/cat/_id.vue' /* webpackChunkName: "pages/cat/_id" */))
const _14aa3eaa = () => interopDefault(import('../pages/failed_payment/_id.vue' /* webpackChunkName: "pages/failed_payment/_id" */))
const _1b89994a = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _db9173dc = () => interopDefault(import('../pages/opt/_id.vue' /* webpackChunkName: "pages/opt/_id" */))
const _cd102e06 = () => interopDefault(import('../pages/pro/_erpId.vue' /* webpackChunkName: "pages/pro/_erpId" */))
const _2745ea7a = () => interopDefault(import('../pages/search/_page.vue' /* webpackChunkName: "pages/search/_page" */))
const _4db7b171 = () => interopDefault(import('../pages/success_order/_id.vue' /* webpackChunkName: "pages/success_order/_id" */))
const _711eac29 = () => interopDefault(import('../pages/success_payment/_id.vue' /* webpackChunkName: "pages/success_payment/_id" */))
const _73e9ecef = () => interopDefault(import('../pages/cat/_cat/opt/_page.vue' /* webpackChunkName: "pages/cat/_cat/opt/_page" */))
const _ed4e7f2e = () => interopDefault(import('../pages/cat/_cat/pro/_page.vue' /* webpackChunkName: "pages/cat/_cat/pro/_page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _7453ad1c,
    name: "cart___uk"
  }, {
    path: "/ConfirmPayment",
    component: _04fb5942,
    name: "ConfirmPayment___uk"
  }, {
    path: "/delivery",
    component: _0382c473,
    name: "delivery___uk"
  }, {
    path: "/gallery",
    component: _33443dee,
    name: "gallery___uk"
  }, {
    path: "/guarantee",
    component: _526845bf,
    name: "guarantee___uk"
  }, {
    path: "/my",
    component: _4074bab0,
    name: "my___uk",
    children: [{
      path: "compare",
      component: _0efe317e,
      children: [{
        path: "",
        component: _f219bcfe,
        name: "my-compare___uk"
      }, {
        path: ":alias",
        component: _34277f32,
        name: "my-compare-alias___uk"
      }]
    }, {
      path: "Discount",
      component: _117f71d0,
      name: "my-Discount___uk"
    }, {
      path: "Orders",
      component: _5394a67c,
      name: "my-Orders___uk"
    }, {
      path: "Profile",
      component: _d4d9b7bc,
      name: "my-Profile___uk"
    }, {
      path: "Reviews",
      component: _45d3f4d8,
      name: "my-Reviews___uk"
    }, {
      path: "Wishes",
      component: _5e2d9b2c,
      name: "my-Wishes___uk"
    }]
  }, {
    path: "/news",
    component: _1d403a92,
    name: "news___uk"
  }, {
    path: "/payment",
    component: _271acabd,
    name: "payment___uk"
  }, {
    path: "/ru",
    component: _482400f4,
    name: "index___ru"
  }, {
    path: "/auth/ForgotPassword",
    component: _3df15e79,
    name: "auth-ForgotPassword___uk"
  }, {
    path: "/auth/Login",
    component: _515e8a1e,
    name: "auth-Login___uk"
  }, {
    path: "/auth/Logout",
    component: _2783c225,
    name: "auth-Logout___uk"
  }, {
    path: "/auth/Registration",
    component: _60ffd794,
    name: "auth-Registration___uk"
  }, {
    path: "/auth/ResetPassword",
    component: _95f31b82,
    name: "auth-ResetPassword___uk"
  }, {
    path: "/pages/about_us",
    component: _5192d412,
    name: "pages-about_us___uk"
  }, {
    path: "/pages/blog",
    component: _a7cdb8ee,
    name: "pages-blog___uk"
  }, {
    path: "/pages/Contacts",
    component: _13a2285a,
    name: "pages-Contacts___uk"
  }, {
    path: "/pages/dealer_invitation",
    component: _302006a8,
    name: "pages-dealer_invitation___uk"
  }, {
    path: "/pages/director",
    component: _0074fa1a,
    name: "pages-director___uk"
  }, {
    path: "/pages/panels",
    component: _c17336d4,
    name: "pages-panels___uk"
  }, {
    path: "/pages/pre_order",
    component: _2415d14a,
    name: "pages-pre_order___uk"
  }, {
    path: "/pages/Ruloni",
    component: _4fc6e7f0,
    name: "pages-Ruloni___uk"
  }, {
    path: "/pages/vacancies",
    component: _1f1bef82,
    name: "pages-vacancies___uk"
  }, {
    path: "/ru/cart",
    component: _7453ad1c,
    name: "cart___ru"
  }, {
    path: "/ru/ConfirmPayment",
    component: _04fb5942,
    name: "ConfirmPayment___ru"
  }, {
    path: "/ru/delivery",
    component: _0382c473,
    name: "delivery___ru"
  }, {
    path: "/ru/gallery",
    component: _33443dee,
    name: "gallery___ru"
  }, {
    path: "/ru/guarantee",
    component: _526845bf,
    name: "guarantee___ru"
  }, {
    path: "/ru/my",
    component: _4074bab0,
    name: "my___ru",
    children: [{
      path: "compare",
      component: _0efe317e,
      children: [{
        path: "",
        component: _f219bcfe,
        name: "my-compare___ru"
      }, {
        path: ":alias",
        component: _34277f32,
        name: "my-compare-alias___ru"
      }]
    }, {
      path: "Discount",
      component: _117f71d0,
      name: "my-Discount___ru"
    }, {
      path: "Orders",
      component: _5394a67c,
      name: "my-Orders___ru"
    }, {
      path: "Profile",
      component: _d4d9b7bc,
      name: "my-Profile___ru"
    }, {
      path: "Reviews",
      component: _45d3f4d8,
      name: "my-Reviews___ru"
    }, {
      path: "Wishes",
      component: _5e2d9b2c,
      name: "my-Wishes___ru"
    }]
  }, {
    path: "/ru/news",
    component: _1d403a92,
    name: "news___ru"
  }, {
    path: "/ru/payment",
    component: _271acabd,
    name: "payment___ru"
  }, {
    path: "/ru/auth/ForgotPassword",
    component: _3df15e79,
    name: "auth-ForgotPassword___ru"
  }, {
    path: "/ru/auth/Login",
    component: _515e8a1e,
    name: "auth-Login___ru"
  }, {
    path: "/ru/auth/Logout",
    component: _2783c225,
    name: "auth-Logout___ru"
  }, {
    path: "/ru/auth/Registration",
    component: _60ffd794,
    name: "auth-Registration___ru"
  }, {
    path: "/ru/auth/ResetPassword",
    component: _95f31b82,
    name: "auth-ResetPassword___ru"
  }, {
    path: "/ru/pages/about_us",
    component: _5192d412,
    name: "pages-about_us___ru"
  }, {
    path: "/ru/pages/blog",
    component: _a7cdb8ee,
    name: "pages-blog___ru"
  }, {
    path: "/ru/pages/Contacts",
    component: _13a2285a,
    name: "pages-Contacts___ru"
  }, {
    path: "/ru/pages/dealer_invitation",
    component: _302006a8,
    name: "pages-dealer_invitation___ru"
  }, {
    path: "/ru/pages/director",
    component: _0074fa1a,
    name: "pages-director___ru"
  }, {
    path: "/ru/pages/panels",
    component: _c17336d4,
    name: "pages-panels___ru"
  }, {
    path: "/ru/pages/pre_order",
    component: _2415d14a,
    name: "pages-pre_order___ru"
  }, {
    path: "/ru/pages/Ruloni",
    component: _4fc6e7f0,
    name: "pages-Ruloni___ru"
  }, {
    path: "/ru/pages/vacancies",
    component: _1f1bef82,
    name: "pages-vacancies___ru"
  }, {
    path: "/",
    component: _482400f4,
    name: "index___uk"
  }, {
    path: "/ru/articles/show/:slug?",
    component: _0a2d5a84,
    name: "articles-show-slug___ru"
  }, {
    path: "/ru/payment/mono-confirmation/:id?",
    component: _7bf27316,
    name: "payment-mono-confirmation-id___ru"
  }, {
    path: "/articles/show/:slug?",
    component: _0a2d5a84,
    name: "articles-show-slug___uk"
  }, {
    path: "/payment/mono-confirmation/:id?",
    component: _7bf27316,
    name: "payment-mono-confirmation-id___uk"
  }, {
    path: "/ru/articles/:page?",
    component: _247b3818,
    name: "articles-page___ru"
  }, {
    path: "/ru/cat/:id?",
    component: _47314435,
    name: "cat-id___ru"
  }, {
    path: "/ru/failed_payment/:id?",
    component: _14aa3eaa,
    name: "failed_payment-id___ru"
  }, {
    path: "/ru/news/:slug",
    component: _1b89994a,
    name: "news-slug___ru"
  }, {
    path: "/ru/opt/:id?",
    component: _db9173dc,
    name: "opt-id___ru"
  }, {
    path: "/ru/pro/:erpId?",
    component: _cd102e06,
    name: "pro-erpId___ru"
  }, {
    path: "/ru/search/:page?",
    component: _2745ea7a,
    name: "search-page___ru"
  }, {
    path: "/ru/success_order/:id?",
    component: _4db7b171,
    name: "success_order-id___ru"
  }, {
    path: "/ru/success_payment/:id?",
    component: _711eac29,
    name: "success_payment-id___ru"
  }, {
    path: "/ru/cat/:cat?/opt/:page?",
    component: _73e9ecef,
    name: "cat-cat-opt-page___ru"
  }, {
    path: "/ru/cat/:cat?/pro/:page?",
    component: _ed4e7f2e,
    name: "cat-cat-pro-page___ru"
  }, {
    path: "/articles/:page?",
    component: _247b3818,
    name: "articles-page___uk"
  }, {
    path: "/cat/:id?",
    component: _47314435,
    name: "cat-id___uk"
  }, {
    path: "/failed_payment/:id?",
    component: _14aa3eaa,
    name: "failed_payment-id___uk"
  }, {
    path: "/news/:slug",
    component: _1b89994a,
    name: "news-slug___uk"
  }, {
    path: "/opt/:id?",
    component: _db9173dc,
    name: "opt-id___uk"
  }, {
    path: "/pro/:erpId?",
    component: _cd102e06,
    name: "pro-erpId___uk"
  }, {
    path: "/search/:page?",
    component: _2745ea7a,
    name: "search-page___uk"
  }, {
    path: "/success_order/:id?",
    component: _4db7b171,
    name: "success_order-id___uk"
  }, {
    path: "/success_payment/:id?",
    component: _711eac29,
    name: "success_payment-id___uk"
  }, {
    path: "/cat/:cat?/opt/:page?",
    component: _73e9ecef,
    name: "cat-cat-opt-page___uk"
  }, {
    path: "/cat/:cat?/pro/:page?",
    component: _ed4e7f2e,
    name: "cat-cat-pro-page___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
